import React from 'react';
import './modal.scss';
import { Modal } from 'semantic-ui-react';
import {FormattedMessage} from "react-intl";

const ModalWrapper = props => {
    return (
        <Modal closeIcon onClose={props.onDismiss} open={props.open} size='tiny'>
            <Modal.Header className="modal-heading"><FormattedMessage id={props.title} defaultMessage={props.title}/> </Modal.Header>
            <Modal.Content className="modal-content">
                {props.content}
            </Modal.Content>
            <Modal.Actions>
                {props.actions}
            </Modal.Actions>
        </Modal>
    );
}

export default ModalWrapper;