import React, {useContext} from "react";
import ConfirmDigitalIdentifiers from "./ConfirmDigitalIdentifiers";
import StyledButton from "./StyledButton/StyledButton";
import './../styles/pagev2.css';
import ReCAPTCHA from "react-google-recaptcha";
import {connect} from 'react-redux';
import {getUserData} from '../actions';
import LanguageContext from "../contexts/LanguageContext";
const RECAPTCHA_KEY = '6Ldyr1MUAAAAAG8wRyfU9cjCQPFEsgjHDbYvVRkq';
const recaptchaRef = React.createRef();

const ShowMeDataForm = ({showShowMeData, getUserData}) => {
    const languageContext = useContext(LanguageContext);

    const onShowMeData = event => {
        event.preventDefault();
        getUserData({"g-recaptcha-response": recaptchaRef.current.getValue()});
    }

    if (showShowMeData) {
        return (
            <form className="form" onSubmit={onShowMeData}>
                <div style={{paddingBottom: '2rem'}}>
                    <ConfirmDigitalIdentifiers/>
                    <ReCAPTCHA id="recaptcha" sitekey={RECAPTCHA_KEY} ref={recaptchaRef}
                               hl={languageContext.language.value}/>
                </div>
                <StyledButton type="strong large primary" text='sar.buttons.show.date'/>
            </form>
        );
    } else {
        return null;
    }
};

const mapStateToProps = state => {
    return {
        showShowMeData: (!state.userInfo.userId)
    };
}

export default connect(mapStateToProps, {getUserData})(ShowMeDataForm);
