import React, { useState } from 'react';
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css'
import './DataGrid.scss';
import FilterBar from "./FilterBar";

const DataGrid = (
           {columnDefs,
            rowData,
            style,
            hasQuickFilter,
            paginationPageSize})=> {
        const [gridApi, setGridApi] = useState(null);

        const onGridReady = (params) => {
                setGridApi(params.api);
        }

        const onFilterChanged = (term) => {
                if (gridApi) {
                        gridApi.setQuickFilter(term);
                }
        }

        return (
            <div className="taboola-theme" style={ style }>
                {hasQuickFilter && <FilterBar onFormSubmit={onFilterChanged}/>}
                <AgGridReact
                    className='ag-theme-material taboola-grid taboola-theme'
                    rowData={rowData}
                    columnDefs = {columnDefs}
                    onGridReady={onGridReady}
                    pagination={paginationPageSize ? true : false}
                    paginationPageSize={paginationPageSize}
                    rowClassRules={{
                            'disabled': 'data.removed'
                    }}
                    domLayout='autoHeight' >
                </AgGridReact>
            </div>
        );
};

export default DataGrid;