import React from "react";
import StyledButtonWithLabel from "./StyledButtonWithLabel/StyledButtonWithLabel";
import {removeSegment} from '../actions';
import {connect} from "react-redux";
import StyledButton from "./StyledButton/StyledButton";
import Message from "./Message";
import ModalWrapper from "./Modal/ModalWrapper";
import {ALL} from "../actions/types";
import {FormattedMessage} from "react-intl";

class DeleteMyData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {confirmationOpen: false};
    }

    componentDidUpdate() {
        if (this.state.confirmationOpen && this.props.removed) {
            this.onConfirmationDismiss();
        }
    }

    onConfirmationDismiss = () => {
        this.setState({confirmationOpen: false})
    }

    onConfirmationOpen = () => {
        this.setState({confirmationOpen: true})
    }

    renderActions() {
        return (
            <React.Fragment>
                <StyledButton onClick={this.onConfirmationDismiss} type="strong medium" text='sar.buttons.cancel'/>
                <StyledButton onClick={() => this.props.removeSegment()} type="ghost medium" text='sar.buttons.remove'/>
            </React.Fragment>
        );
    }

    renderMessageContent() {
        return (
            <div>
                <b><FormattedMessage id='sar.delete.my.data.confirmation.content'/></b>
                <p/>
                <Message type='info' message='sar.delete.my.data.confirmation.note'/>
            </div>
        );
    }

    showConfirmation() {
        if (this.state.confirmationOpen) {
            return <ModalWrapper
                open={this.state.confirmationOpen}
                title='sar.delete.my.data.confirmation.header'
                contentHeader={this.renderMessageContent()}
                content={this.renderMessageContent()}
                actions={this.renderActions()}
                onDismiss={this.onConfirmationDismiss}
            />
        }
    }

    render() {
        let disabled = false;
        if (this.props.removed) {
            disabled = true;
        }

        return (
            <React.Fragment>
                <StyledButtonWithLabel
                    buttonText='sar.delete.my.data.confirmation.header'
                    label='sar.delete.my.data.confirmation.label'
                    type="ghost strong large"
                    onClick={this.onConfirmationOpen}
                    disabled={disabled}/>
                {this.showConfirmation()}
            </React.Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        removed: state.removedSegments.includes(ALL)
    };
};

export default connect(mapStateToProps, {removeSegment})(DeleteMyData);