import defaultMessages from './messages/messages.en';

const messagesCache ={en: defaultMessages};

export const getMessages = async (locale, onMessagesChanged) => {
    const currentMessages = messagesCache[locale];
    if (currentMessages) {
        return currentMessages;
    }

    try {
        const { default: messages } = await import(`./messages/messages.${locale}.js`);
        const mergedMessages = { ...defaultMessages, ...messages };
        messagesCache[locale] = mergedMessages;

        return mergedMessages;
    } catch (e) {
        console.warn(`Cannot find messages for ${locale}, changing to 'en'`);
        return defaultMessages;
    }
}

export const getDefaultMessages = () => {
    return messagesCache.en;
}