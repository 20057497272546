import React from "react";
import { getDefaultMessages, getMessages } from "../i18n/I18nMessagesUtils";
import { matchPath } from "react-router-dom";

export const DEFAULT_LANGUAGE = "en";
export const LANGUAGE_OPTIONS = [
  { value: DEFAULT_LANGUAGE, label: "English" },
  { value: "de", label: "Deutsch (German)" },
  { value: "nl", label: "Nederlands (Dutch)" }
];

const Context = React.createContext(DEFAULT_LANGUAGE);

export class LanguageStore extends React.Component {
  constructor(props) {
    super(props);
    const defaultLanguage = this.getDefaultLanguage();
    const br = matchPath(window.location.href, { path: "*/br" });
    const lang = br && br.isExact ? { value: "pt-BR" } : defaultLanguage;
    this.state = {
      defaultLanguage: defaultLanguage,
      language: lang,
      messages: getDefaultMessages(),
    };
    this.onLanguageChange(this.state.language);
  }

  onLanguageChange = (languageOption) => {
    getMessages(languageOption.value).then((newMessages) =>
      this.setState({ language: languageOption, messages: newMessages })
    );
  };

  getDefaultLanguage = () => {
    const browserLanguage = this.extractLanguage(navigator.language);
    let selectedLanguage = LANGUAGE_OPTIONS.find(
      (e) => e.value === browserLanguage
    );

    if (!selectedLanguage) {
      selectedLanguage = LANGUAGE_OPTIONS.find(
        (e) => e.value === DEFAULT_LANGUAGE
      );
    }

    return selectedLanguage;
  };

  extractLanguage = (browserLang) => {
    if (!browserLang) {
      return DEFAULT_LANGUAGE;
    }

    return browserLang.substr(0, 2);
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          onLanguageChange: this.onLanguageChange,
          language: this.state.language,
          messages: this.state.messages,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default Context;
