import React from "react";
import {optOut} from '../actions';
import {connect} from "react-redux";
import StyledButton from "./StyledButton/StyledButton";
import ModalWrapper from "./Modal/ModalWrapper";
import ToggleSwitchWithLabel from "./ToggleSwitchWithLabel/ToggleSwitchWithLabel";
import {FormattedMessage} from "react-intl";

class OptOut extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirmationOpen: false,
            checked: false
        };
    }

    onConfirmationDismiss = () => {
        this.setState({confirmationOpen: false,
                          checked: false})
    }

    onConfirmationOpen = () => {
        this.setState({confirmationOpen: true,
                      checked: true})
    }

    onOptOut = () => {
        this.props.optOut();
        this.setState({checked: true});
        this.onConfirmationDismiss();
    }

    renderActions() {
        return (
            <React.Fragment>
                <StyledButton onClick={this.onConfirmationDismiss} type="strong medium" text='sar.buttons.cancel'/>
                <StyledButton onClick={this.onOptOut} type="ghost medium" text='sar.optout.button'/>
            </React.Fragment>
        );
    }

    renderMessageContent() {
        return (
            <div>
                <FormattedMessage id='sar.optout.confirmation.content'/>
            </div>
        );
    }

    showConfirmation() {
        if (this.state.confirmationOpen) {
            return <ModalWrapper
                open={this.state.confirmationOpen}
                title='sar.optout.confirmation.header'
                contentHeader={this.renderMessageContent()}
                content={this.renderMessageContent()}
                actions={this.renderActions()}
                onDismiss={this.onConfirmationDismiss}
            />
        }
    }

    render() {
        return (
            <React.Fragment>
                <ToggleSwitchWithLabel
                    text='sar.optout.button'
                    checked={this.state.checked}
                    label='sar.optout.label'
                    onChange={this.onConfirmationOpen}/>
                {this.showConfirmation()}
            </React.Fragment>
        );
    }
};

export default connect(null, {optOut})(OptOut);

