import React from "react";
import {connect} from "react-redux";
import DeviceInformation from "./DeviceInformation";
import TaboolaSegments from "./TaboolaSegments";
import ThirdPartySegments from "./ThirdPartySegments";
import ExportData from "./ExportData";
import "./AccessRequestInfo.scss";
import DeleteMyData from "./DeleteMyData";
import OptOut from "./OptOut";
import Message from "./Message";
import OptIn from "./OptIn";
import {FormattedMessage} from "react-intl";

export const PAGINATION_SEGMENTS_TABLE_SIZE = 25;

class AccessRequestInfo extends React.Component {
    render() {
        if (!this.props.message && this.props.userId && this.props.isOptedIn) {
            return (
                <div className="user_info_container">
                    <p><FormattedMessage id='sar.information.thank.you'/>:</p>
                    <DeviceInformation/>
                    <TaboolaSegments/>
                    <p/>
                    <ThirdPartySegments/>
                    <ExportData text='sar.buttons.download.csv' filename="taboola-data.csv"/>
                    <p>
                        <FormattedMessage id='sar.information.share.disclosure'
                            values={{
                                adp: chunks => <a href="https://www.taboola.com/data-partners">{chunks}</a>,
                                apdasp: chunks => <a href="https://www.taboola.com/programmatic-demand-and-supply-partners">{chunks}</a>,
                            }}/>
                    </p>
                    <DeleteMyData/>
                    <p/>
                    <OptOut/>
                    <p/>
                    <p>
                        <FormattedMessage id='sar.information.optout.external'
                            values={{
                                ios: chunks => <a href="https://support.apple.com/en-euro/HT202074">{chunks}</a>,
                                android: chunks => <a href="https://support.google.com/ads/answer/2662922">{chunks}</a>
                            }}/>
                    </p>
                    <p>
                        <FormattedMessage id='sar.information.optout.external.nai'
                            values={{
                                nai: chunks => <a href="https://www.networkadvertising.org/mobile-choice/">{chunks}</a>
                            }}/>
                    </p>

                </div>
            );
        } else if (!this.props.isOptedIn) {
            return (
            <div className="user_info_container">
                <Message message='sar.optout.user.is.opt.out' showIcon={true} type="negative"/>
                <OptIn/>
            </div>);
        }

        return null;
    }
}

const mapStateToProps = state => {
    return {
        message: state.userInfo.message,
        userId: state.userInfo.userId,
        isOptedIn: state.optInOut.isOptedIn
    }
};

export default connect (mapStateToProps)(AccessRequestInfo);

