import React, {useState, useEffect} from "react";
import SearchInput from "../SearchInput/SearchInput";

const FilterBar = ({onFormSubmit}) => {
    const [term, setTerm] = useState('');

    useEffect(() => {
        onFormSubmit(term);
    }, [term, onFormSubmit]);

    return (
        <div>
            <SearchInput value={term} onChange={(event) => setTerm(event.target.value)} placeholder='Search Table'/>
        </div>
    );
};


export default FilterBar;