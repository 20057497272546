import { messages as common } from './messages.common';

const messages = {
    ...common,

    'sar.buttons.cancel': 'Cancel',
    'sar.buttons.remove': 'Remove',
    'sar.buttons.show.date': 'Show me the data',
    'sar.buttons.download.csv': 'Download CSV',

    'sar.message.error.general.header': 'We\'re sorry, we can\'t process your request',

    'sar.main.header': 'How to Access Your Taboola Data',
    'sar.general.description.header': 'Taboola’s Data Subject Access Request Portal',
    'sar.general.description.content': 'This policy outlines what personal data and behavioural information Taboola holds about you and how you can access or delete your personal data.',
    'sar.your.rights.header': 'Your Rights — What You Can Request',
    'sar.your.rights.content': 'You have the right to request that Taboola provide you with access to the personal data that we hold '
                               + 'about you, correct your personal data, delete your personal data, and cease or restrict processing '
                               + 'of your personal data. For more information about how we collect, use, and disclose information that we '
                               + 'obtain through our websites and services, please see our '
                               + '<a>Privacy Policy</a>.',
    'sar.what.information.taboola.holds.header':'What Information Taboola Holds',
    'sar.what.information.taboola.holds.content':'<p>Taboola maintains data about you in only pseudonymized form, which means that we do not know your '
                                                 + 'identity because we do not process your name, email address, or other identifiable data. Instead, we '
                                                 + 'only process digital identifiers such as cookie IDs, IP addresses (truncated in Germany), mobile '
                                                 + 'advertising IDs on your device, and in some limited circumstances, your hashed email address '
                                                 + '(for a full list of pseudonymized data that we process, please see our '
                                                 + '<a>Privacy Policy</a>). This data is processed in order to provide you with personalized content and advertisements. '
                                                 + '</p> '
                                                 + '<p> '
                                                 + 'Please note that Taboola retains the data associated with your digital identifiers for only a '
                                                 + 'limited period of time (up to thirteen months, but often a shorter period of time). For this reason, we may '
                                                 + 'not have any information pertaining to your request in our records, and in such a case, we will inform '
                                                 + 'you that we do not have any records associated with your digital identifiers in our systems. '
                                                 + '</p> '
                                                 + '<p> '
                                                 + 'Through this portal, Taboola will respond to your data access request by identifying your digital '
                                                 + 'identifiers that we do use and the personal data that we process in connection with them. Once you '
                                                 + 'affirm below that you are the subject of this personal data, we will provide you with details about '
                                                 + 'the advertising profile we hold about you.'
                                                 + '</p> ',
    'sar.confirm.digital.identifiers.header':'Yes, I Want My Personal Data — Tell Me How',
    'sar.confirm.digital.identifiers.content':'<p>'
                                   + 'In order to keep your personal data secure and confirm that we have received a bona fide request, we '
                                   + 'need to take steps to verify your identity and right to access the requested data. If we are unable '
                                   + 'to verify your identity, we will be unable to comply with your request. '
                                   + '</p> '
                                   + '<p> '
                                   + 'Please carefully complete the below, which confirms that the digital identifiers associated with the '
                                   + 'device that you are currently using to make this request are indeed your own and associated with '
                                   + 'you: '
                                   + '</p>',
    'sar.confirm.digital.identifiers.approve.1': 'I own or operate the device from which I am making this request',
    'sar.confirm.digital.identifiers.approve.2': 'I am the sole user of this device',
    'sar.confirm.digital.identifiers.approve.3': 'I am not requesting this personal data for any fraudulent, criminal, malicious, or otherwise unlawful purposes',

    'sar.information.thank.you': 'Thank you for affirming that you are the true subject of this personal data. Here are details about the advertising profile that Taboola holds about you, as it relates to the specific device through which you made this request. (Please note that if you wish to understand what personal data Taboola processes about you in connection with another device, you must make another request using that other device)',
    'sar.information.share.disclosure': 'We may share the above data with (1) our <adp>data partners</adp>, so that we can connect you '
                                        + 'with relevant content by allowing our advertisers to target specific audience segments, and (ii) our '
                                        + '<apdasp>programmatic demand and supply partners</apdasp>, so that we can serve you with tailored advertisements.',
    'sar.information.optout.external' : 'If you would like to exercise your opt out or data deletion rights so that we will no longer use '
                                        + 'your personal data in a mobile application ("App"), we ask you to please use the advertising choice '
                                        + 'mechanisms provided by your mobile operating system. On <ios>iOS</ios> '
                                        + 'devices, this option is referred to as "Personalized Ads" and in <android>Android</android> '
                                        + 'devices, this is referred to as "Opt Out of Interest Based Ads".',
    'sar.information.optout.external.nai': 'For your reference, the <nai>NAI</nai> has outlined step-by-step guidance to help you opt out on your mobile devices.',
    'sar.device.information.header': 'Device Information',
    'sar.device.information.user.id': 'User ID',
    'sar.device.information.os': 'Operating System',
    'sar.device.information.platform': 'Platform',
    'sar.device.information.browser': 'Browser',
    'sar.device.information.browser.version': 'Browser Version',
    'sar.device.information.cookie.creation.time': 'Cookie Creation Time',

    'sar.segments.taboola.header': 'Taboola Segments',
    'sar.segments.taboola.content': 'These are some of the segments matched to you based on your activity on the Taboola network.',
    'sar.segments.3rdparty.header': '3rd Party Partners Segments',
    'sar.segments.3rdparty.content': 'These are some of the segments matched to you based on our 3rd party data partners.',
    'sar.segments.table.columns.header.segmentname': 'Segment Name',
    'sar.segments.table.columns.header.partner': 'Partner',
    'sar.segments.table.columns.header.creationdate': 'Creation Date',
    'sar.segments.table.columns.header.action': 'Action',
    'sar.segments.table.actions.remove.segment.button': 'Remove from segment',
    'sar.segments.table.actions.remove.segment.confirmation.header': 'Remove from segment',
    'sar.segments.table.actions.remove.segment.confirmation.content': 'Are you sure you want to be removed from segment {segmentName}?',
    'sar.segments.table.actions.remove.segment.confirmation.note': 'Please note, it will take up to a day to process the request',
    'sar.segments.table.actions.remove.segment.removed': 'Sent request to remove',
    'sar.segments.nothingtoshow': 'Nothing to show',

    'sar.delete.my.data.confirmation.header': 'Delete My Data',
    'sar.delete.my.data.confirmation.content': 'Are you sure you want to delete your data?',
    'sar.delete.my.data.confirmation.note': 'Please note, it will take up to a day to process the request',
    'sar.delete.my.data.confirmation.label': 'Continue serving me personalized ads, but do not use prior data',

    'sar.optout.button': 'Opt-Out',
    'sar.optout.label': 'Only serve me contextual ads going forward',
    'sar.optout.confirmation.header': 'Opt-Out from Taboola',
    'sar.optout.confirmation.content': 'Are you sure you want to Opt-Out from Taboola?',
    'sar.optout.user.is.opt.out': 'You are opted-out from Taboola',

    'sar.contact.support.message': 'For general customer support inquiries relating to payment issues, technical issues, or any other issues you may be experiencing, please contact Taboola’s Customer Support Team, at support@taboola.com. General customer service questions will not be answered if submitted through this form.'



};

export default messages;