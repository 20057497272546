import React from "react";
import Switch from 'react-switch';
import './ToggleSwitch.scss';
import styles from './ToggleSwitch.scss';
import {FormattedMessage} from "react-intl";


const ToggleSwitch = ({checked, text, onChange, disabled, color, height, width, checkedIcon ,uncheckedIcon}) => {
    return (
        <label className="tbl-toggle-switch">
            <Switch className="react-switch" onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                    onColor={color}
                    height={height}
                    width={width}
                    checkedIcon={checkedIcon}
                    uncheckedIcon={uncheckedIcon}/>
            <span><FormattedMessage id={text} defaultMessage={text}/></span>
        </label>
    );
};

ToggleSwitch.defaultProps = {
    disabled: false,
    checked: false,
    color: styles.blue,
    height: 14,
    width: 28,
    checkedIcon: false,
    uncheckedIcon: false
};

export default ToggleSwitch;