import React from 'react';
import {FormattedMessage} from "react-intl";

const typeToDesign = {
    info: { class: 'info', icon: 'info'},
    positive: {class: 'positive', icon: 'check'},
    error: { class: 'error', icon: 'times'},
    negative: { class: 'negative', icon: 'exclamation'},
    warning: { class: 'warning', icon: 'exclamation'},
    normal: {class: '', icon: ''}
};

const Message = ({type, message, header = null, showIcon = false}) => {
    const design = typeToDesign[type];
    return (
        <div className={`ui icon ${design.class} message`}>
            {showIcon ? <i className={`${design.icon} circle icon`} /> : ''}
            <div className="content">
                {header ?
                 <div className="header">
                     <FormattedMessage id={header} defaultMessage={header}/>
                 </div> :
                 ''}
                <p/>
                <FormattedMessage id={message} defaultMessage={message}/>
            </div>
        </div>
    );
};

export default Message;