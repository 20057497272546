import React from "react";
import {connect} from "react-redux";
import { CSVLink } from "react-csv";
import './StyledButton/StyledButton.scss';
import {FormattedMessage} from "react-intl";

const headers = ["Segment Name", "Segment Creation Time", "Partner" ];


const ExportData = ({text, filename, dataToExport}) => {
    return (
        <div className="export-button">
            <CSVLink data={dataToExport} headers={headers} filename={filename} type="button" className="tbl-btn tbl-styled-btn primary strong">
                <FormattedMessage id={text} defaultMessage={text}/>
            </CSVLink>
        </div>
    );
}

const mapStateToProps = state => {
    const dataToExport = [];
    dataToExport.push(['Device Information']);
    dataToExport.push(['User ID:', state.userInfo.userId]);
    dataToExport.push(['Operating System:', state.userInfo.osname]);
    dataToExport.push(['Platform:', state.userInfo.platform]);
    dataToExport.push(['Browser:', state.userInfo.browser]);
    dataToExport.push(['Browser Version:', state.userInfo.browserVersion]);
    dataToExport.push(['Cookie Creation Time:', state.userInfo.guidCreationDate]);
    dataToExport.push(['Segments']);
    state.userInfo.segment.forEach(
        segment => {
            if (!state.removedSegments.includes(segment[0])) {
                dataToExport.push([segment[1], segment[2], segment[3]]);
            }
        });
    return {dataToExport};
};

export default connect(mapStateToProps)(ExportData);