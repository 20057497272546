import {ALL} from "../actions/types";

export const isSegmentRemoved = (removedSegments, segmentId) => {
    return (removedSegments.includes(segmentId) ||
        removedSegments.includes(ALL));
}

export const getUrlParams = () => {
    const params = {};
    const urlSearchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of urlSearchParams) {
        params[key] = value;
    }
    return params;
}

export const mergeParams = (params1, params2) => {
    return {...params1, ...params2};
}