import React from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";

class DeviceInformation extends React.Component {
    render() {
        return (
            <div className="user_info_container">
                <h2 className="heading_segments"><FormattedMessage id='sar.device.information.header'/></h2>
                <p/>
                <p><FormattedMessage id='sar.device.information.user.id'/>: {this.props.userId}</p>
                <p><FormattedMessage id='sar.device.information.os'/>: {this.props.osname}</p>
                <p><FormattedMessage id='sar.device.information.platform'/>: {this.props.platform}</p>
                <p><FormattedMessage id='sar.device.information.browser'/>: {this.props.browser}</p>
                <p><FormattedMessage id='sar.device.information.browser.version'/>: {this.props.browserVersion}</p>
                <p><FormattedMessage id='sar.device.information.cookie.creation.time'/>: {this.props.guidCreationDate}</p>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userId: state.userInfo.userId,
        platform: state.userInfo.platform,
        osname: state.userInfo.osname,
        browser: state.userInfo.browser,
        browserVersion: state.userInfo.browserVersion,
        guidCreationDate: state.userInfo.guidCreationDate
    }
};

export default connect (mapStateToProps)(DeviceInformation);