import React from "react";
import '../../styles/icons/taboola-icons.scss';
import '../../styles/icons/icons.scss';
import './SearchInput.scss';

const SearchInput = ({term, placeholder, onChange}) => {
    return (
        <div className="expandable-input-container open">
            <div className="expandable-input taboola-input full-width">
                <div className="input-wrapper">
                    <input className="search-input input" type="text" value={term} placeholder={placeholder} onChange={onChange}/>
                    <div className="input-icon" role="button" tabIndex="0">
                        <i name="search" className="tbl-icon tbl-search"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchInput;