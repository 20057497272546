import React from 'react';
import TaboolaSegmentsTable from "./TaboolaSegmentsTable";
import {FormattedMessage} from "react-intl";
import {isSegmentRemoved} from "../helpers"
import {connect} from "react-redux"
import Message from "./Message"

const TaboolaSegments = (props) => {

    const showTableOrMessage = () => {
        if (props.taboolaSegments.length > 0) {
            return (
                <React.Fragment>
                    <p><FormattedMessage id='sar.segments.taboola.content'/></p>
                    <TaboolaSegmentsTable taboolaSegments={props.taboolaSegments}/>
                </React.Fragment>
            );
        } else {
            return <Message message='sar.segments.nothingtoshow' showIcon={true} type="positive"/>;
        }
    }

    return (
        <div className="user_info_container">
            <h2 className="heading_segments"><FormattedMessage id='sar.segments.taboola.header'/></h2>
            {showTableOrMessage()}
        </div>
    );
};

const mapStateToProps = state => {
    var taboolaSegments = [];
    state.userInfo.segment.forEach(
        segment => {
            if (segment[3] === 'TABOOLA') {
                taboolaSegments.push({
                                         id: segment[0],
                                         segmentName: segment[1],
                                         creationDate: segment[2],
                                         removed: isSegmentRemoved(state.removedSegments, segment[0])
                                     });
            }
        }
    );

    return { taboolaSegments };
}

export default connect (mapStateToProps)(TaboolaSegments);