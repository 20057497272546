import accessRequest from "../api/accessRequest";
import {ALL, GET_USER_DATA, OPT_IN, OPT_OUT, REMOVE_SEGMENT} from "./types";
import {getUrlParams, mergeParams} from "../helpers";

export const getUserData = (formValues) => {
    return async (dispatch) => {
          // formValues = {...formValues, taboola_id_debug: '{place your user id here}'};
        const response = await accessRequest.get('/trs/agree', {params: mergeParams(formValues, getUrlParams())})
            .catch((error) => {
                   console.log(error);
               });
        if (response) {
            dispatch({type: GET_USER_DATA, payload: response.data});
        } else {
            dispatch({type: GET_USER_DATA, payload: {message: 'An error occurred, please try again later'}});
        }
    }
};

export const removeSegment = (id) => {
    return async (dispatch) => {
        if (id) {
            const formValues = {"seg_id": id};
            await accessRequest.get('/trs/segments/deleteSegment', {params: mergeParams(formValues, getUrlParams())})
                .catch((error) => {
                    console.log(error);
                });
            dispatch({type: REMOVE_SEGMENT, payload: id});
        } else {
            await accessRequest.get('/trs/segments/deleteSegment',{params: getUrlParams()})
            .catch((error) => {
                console.log(error);
            });
            dispatch({type: REMOVE_SEGMENT, payload: ALL});
        }
    }
};

export const optOut = () => {
    return async (dispatch) => {
        await accessRequest.get('/trs/segments/optout', {params: getUrlParams()})
            .catch((error) => {
                console.log(error);
            });
        dispatch({type: OPT_OUT} )
    }
};

export const optIn = () => {
    return async (dispatch) => {
        await accessRequest.get('/trs/segments/optin', {params: getUrlParams()})
            .catch((error) => {
                console.log(error);
            });;
        dispatch({type: OPT_IN} );
    }
};

