import React from 'react';
import ThirdPartySegmentsTable from "./ThirdPartySegmentsTable";
import {FormattedMessage} from "react-intl";
import {isSegmentRemoved} from "../helpers"
import {connect} from "react-redux"
import Message from "./Message"

const ThirdPartySegments = (props) => {

    const showTableOrMessage = () => {
        if (props.thirdPartySegments.length > 0) {
            return (
                <React.Fragment>
                    <p><FormattedMessage id='sar.segments.3rdparty.content'/></p>
                    <ThirdPartySegmentsTable thirdPartySegments={props.thirdPartySegments}/>
                </React.Fragment>
            );
        } else {
            return <Message message='sar.segments.nothingtoshow' showIcon={true} type="positive"/>;
        }
    }

    return (
        <div className="user_info_container">
            <h2 className="heading_segments"><FormattedMessage id='sar.segments.3rdparty.header'/></h2>
            {showTableOrMessage()}
        </div>
    );
};

const mapStateToProps = state => {
    var thirdPartySegments = [];
    state.userInfo.segment.forEach(
        segment => {
            if (segment[3] !== 'TABOOLA') {
                thirdPartySegments.push({
                                            id: segment[0],
                                            segmentName: segment[1],
                                            creationDate: segment[2],
                                            partner: segment[3],
                                            removed: isSegmentRemoved(state.removedSegments, segment[0])
                                        });
            }
        }
    );

    return { thirdPartySegments: thirdPartySegments };
};

export default connect (mapStateToProps)(ThirdPartySegments);