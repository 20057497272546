import {GET_USER_DATA} from '../actions/types';

const INITIAL_STATE = {
    message: null,
    segment: null,
    userId: null,
    platform: null,
    osname: null,
    browser: null,
    browserVersion: null,
    guidCreationDate: null
};

const userInfo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USER_DATA:
            return {...state, message: action.payload.message, segment: action.payload.segment, userId: action.payload.userId,
                platform: action.payload.platform, osname: action.payload.osname, browser: action.payload.browser,
                browserVersion: action.payload.browserVersion, guidCreationDate: action.payload.guidCreationDate};
        default:
            return state;
    }
};

export default userInfo;