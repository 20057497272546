import {GET_USER_DATA, OPT_IN, OPT_OUT} from '../actions/types';

const INITIAL_STATE = {isOptedIn: true}

const optInOut = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OPT_IN:
            return {...state, isOptedIn: true};
        case OPT_OUT:
            return {...state, isOptedIn: false};
        case GET_USER_DATA:
            if (action.payload.message === 'You are already opt out.') {
                return {...state, isOptedIn: false};
            } else {
                return {...state, isOptedIn: true};
            }
        default:
            return state;
    }
};

export default optInOut;