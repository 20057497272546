import React from 'react';
import './LanguageSelector.scss';
import Select from 'react-select';

const LanguageSelector = ({onLanguageChange, options, defaultLanguage, language}) => {
    const handleChange = selectedOption => {
        onLanguageChange(selectedOption);
    };

    return (
        <div className='tbl-language-container'>
            <Select className='tbl-language-selector'
                options={options}
                onChange={handleChange}
                defaultValue ={defaultLanguage}
                value={language}
                />
        </div>
    );

}

export default LanguageSelector;