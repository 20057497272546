import React from 'react';
import {connect} from "react-redux";
import DataGrid from "./DataGrid/DataGrid";
import RemoveSegmentButtonRenderer from "./RemovedSegmentButton/RemoveSegmentButtonRenderer";
import {isSegmentRemoved} from "../helpers";
import {useIntl} from "react-intl";
import {PAGINATION_SEGMENTS_TABLE_SIZE} from "./AccessRequestInfo"

const ThirdPartySegmentsTable = (props) => {

    const intl = useIntl();
    const columnsDefs =  [
        { headerName: intl.formatMessage({id: 'sar.segments.table.columns.header.segmentname'}), field: 'segmentName', sortable: true, flex: '1'  },
        { headerName: intl.formatMessage({id: 'sar.segments.table.columns.header.partner'}), field: 'partner', sortable: true, width: 125 },
        { headerName: intl.formatMessage({id: 'sar.segments.table.columns.header.creationdate'}), field: 'creationDate', sortable: true, width: 150 },
        { headerName: intl.formatMessage({id: 'sar.segments.table.columns.header.action'}), field: 'action', cellRendererFramework: RemoveSegmentButtonRenderer }
    ];

    return <DataGrid
        columnDefs={columnsDefs}
        rowData={props.thirdPartySegments}
        paginationPageSize={PAGINATION_SEGMENTS_TABLE_SIZE}
        hasQuickFilter='true'
        />
};

export default ThirdPartySegmentsTable;