import React from "react";
import './../styles/pagev2.css';
import {FormattedMessage} from "react-intl";

const ConfirmDigitalIdentifiers = () => {
    return (
        <div className="formGroup tbl-checkbox">
            <input id="checkBoxIOwnDevice" type="checkbox" required/>
            <label htmlFor="checkBoxIOwnDevice"><FormattedMessage id='sar.confirm.digital.identifiers.approve.1'/></label>
            <br/>
            <input id="checkBoxSoleUser" type="checkbox" required/>
            <label htmlFor="checkBoxSoleUser" required><FormattedMessage id='sar.confirm.digital.identifiers.approve.2'/></label>
            <br/>
            <input id="checkBoxNoFraud" type="checkbox" required/>
            <label htmlFor="checkBoxNoFraud" required><FormattedMessage id='sar.confirm.digital.identifiers.approve.3'/></label>
        </div>
        );
};

export default ConfirmDigitalIdentifiers;
