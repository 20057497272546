import React from 'react';
import StyledButton from "../StyledButton/StyledButton";
import {removeSegment} from '../../actions';
import {connect} from "react-redux";
import ModalWrapper from "../Modal/ModalWrapper";
import Message from "../Message";
import {isSegmentRemoved} from "../../helpers";
import {FormattedMessage} from "react-intl";

class RemoveSegmentButtonRenderer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {confirmationOpen: false};
    }

    componentDidUpdate() {
        if (this.state.confirmationOpen && this.props.removed) {
            this.onConfirmationDismiss();
        }
    }

    onConfirmationDismiss = () => {
        this.setState({confirmationOpen: false})
    }

    onConfirmationOpen = () => {
        this.setState({confirmationOpen: true})
    }

    renderActions() {
        return (
            <React.Fragment>
                <StyledButton onClick={this.onConfirmationDismiss} type="strong medium" text='sar.buttons.cancel'/>
                <StyledButton onClick={() => this.props.removeSegment(this.props.data.id)} type="ghost medium" text='sar.buttons.remove'/>
            </React.Fragment>
        );
    }

    renderContent() {
        return (
            <div>
                <FormattedMessage id='sar.segments.table.actions.remove.segment.confirmation.content'
                    values={{
                        segmentName: this.props.data.segmentName
                    }}/>
                <p/>
                <Message type='info' message='sar.segments.table.actions.remove.segment.confirmation.note'/>
            </div>
        );
    }

    showConfirmation() {
        if (this.state.confirmationOpen) {
            return <ModalWrapper
                open={this.state.confirmationOpen}
                title="Remove from segment"
                contentHeader={this.renderContent()}
                content={this.renderContent()}
                actions={this.renderActions()}
                onDismiss={this.onConfirmationDismiss}
            />
        }
    }

    render() {
        if (!this.props.removed) {
            return (
                <React.Fragment>
                    <span>
                        <StyledButton type="small ghost" onClick={this.onConfirmationOpen} text='sar.segments.table.actions.remove.segment.button'/>
                    </span>
                    {this.showConfirmation()}
                </React.Fragment>
            );
        } else {
            return (
            <span className="ag-disabled">
                <FormattedMessage id='sar.segments.table.actions.remove.segment.removed'/>
            </span>
            );
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        removed: isSegmentRemoved(state.removedSegments, ownProps.data.id)
    };
};

export default connect (mapStateToProps, {removeSegment}, null, { forwardRef: true })(RemoveSegmentButtonRenderer);