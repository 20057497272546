import React, { useContext } from "react";
import mainLogo from "./../images/logo.png";
import ShowMeDataForm from "./ShowMeDataForm";
import "./../styles/pagev2.css";
import ErrorMessage from "./ErrorMessage";
import AccessRequestInfo from "./AccessRequestInfo";
import Message from "./Message";
import { FormattedMessage, IntlProvider } from "react-intl";
import LanguageSelector from "./LanguageSelector/LanguageSelector";
import LanguageContext, {
  DEFAULT_LANGUAGE,
  LANGUAGE_OPTIONS,
} from "../contexts/LanguageContext";
import { matchPath } from "react-router-dom";

const App = () => {
  const languageContext = useContext(LanguageContext);
  const br = matchPath(window.location.href, { path: "*/br" });

  return (
    <IntlProvider
      key={languageContext.language.value}
      locale={languageContext.language.value}
      defaultLocale={DEFAULT_LANGUAGE}
      messages={languageContext.messages}
    >
      <div>
        <div id="access-data-title">
          <section id="access-data-section">
            <img src={mainLogo} className="logo" alt="Taboola Logo" />
            <h1 id="h1">
              <FormattedMessage id="sar.main.header" />
            </h1>
          </section>
        </div>
        <div className="main-container container">
          {!(br && br.isExact) && (
            <LanguageSelector
              onLanguageChange={languageContext.onLanguageChange}
              options={LANGUAGE_OPTIONS}
              defaultLanguage={languageContext.defaultLanguage}
              language={languageContext.language}
            />
          )}
          <article>
            <br />
            <h1>
              <FormattedMessage id="sar.general.description.header" />
            </h1>
            <p>
              <FormattedMessage id="sar.general.description.content" />
            </p>
            <p className="h3">
              <FormattedMessage id="sar.your.rights.header" />
            </p>
            <p>
              <FormattedMessage
                id="sar.your.rights.content"
                values={{
                  a: (chunks) => (
                    <a href="https://www.taboola.com/privacy-policy">
                      {chunks}
                    </a>
                  ),
                }}
              />
            </p>
            <p className="h3">
              <FormattedMessage id="sar.what.information.taboola.holds.header" />
            </p>
            <FormattedMessage
              id="sar.what.information.taboola.holds.content"
              values={{
                a: (chunks) => (
                  <a href="https://www.taboola.com/privacy-policy">{chunks}</a>
                ),
                p: (chunks) => <p>{chunks}</p>,
              }}
            />
            <p className="h3">
              <FormattedMessage id="sar.confirm.digital.identifiers.header" />
            </p>
            <FormattedMessage
              id="sar.confirm.digital.identifiers.content"
              values={{
                p: (chunks) => <p>{chunks}</p>,
              }}
            />
            <ShowMeDataForm />
            <ErrorMessage />
            <Message
              type="info"
              message="sar.contact.support.message"
              showIcon="true"
            />
            <AccessRequestInfo />
          </article>
        </div>
      </div>
    </IntlProvider>
  );
};

export default App;
