import React from "react";
import { connect } from 'react-redux';
import Message from "./Message";

const UserInfoErrorMessage = ({message}) => {

    if (message) {
        return (
            <Message type='error'
                     message={message}
                     header='sar.message.error.general.header'
                     showIcon='true'/>
            );
    }

    return null;
};

const mapStateToProps = state => {
    return {
        message: state.userInfo.message
    }
};

export default connect (mapStateToProps)(UserInfoErrorMessage);
