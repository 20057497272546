import React from "react";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import './ToggleSwitchWithLabel.scss';
import {FormattedMessage} from "react-intl";

const ToggleSwitchWithLabel = ({checked, text, onChange, label}) => {
    return (
        <div className="container">
            <ToggleSwitch text={text} checked={checked} onChange={onChange}/>
            <label className="label"><FormattedMessage id={label} defaultMessage={label}/></label>
        </div>
    );
};

export default ToggleSwitchWithLabel;