import {REMOVE_SEGMENT} from '../actions/types';

const INITIAL_STATE = [];

const removedSegments = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REMOVE_SEGMENT:
            return [...state, action.payload]
        default:
            return state;
    }
};

export default removedSegments;