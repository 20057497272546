
const EMPTY_MESSAGE_ID = 'empty.message';
const ONLY_VALUE_MESSAGE_ID = 'only.value.message';

const messages = {
    [EMPTY_MESSAGE_ID]: ' ',
    [ONLY_VALUE_MESSAGE_ID]: '{value}'
};

export { messages, EMPTY_MESSAGE_ID, ONLY_VALUE_MESSAGE_ID };
