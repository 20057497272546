import React from "react";
import StyledButton from "../StyledButton/StyledButton";
import "./StyledButtonWithLabel.scss";
import {FormattedMessage} from "react-intl";

const StyledButtonWithLabel = ({type, onClick, buttonText, label, disabled}) => {
    return (
        <div className="container">
            <StyledButton type={type} onClick={onClick} text={buttonText} disabled={disabled}/>
            <label className="label"><FormattedMessage id={label} description={label}/></label>
        </div>
    );
}

StyledButtonWithLabel.defaultProps = {
    disabled: false
};

export default StyledButtonWithLabel;