import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import reducers from './reducers';
import {createStore, applyMiddleware, compose} from "redux";
import reduxThunk from 'redux-thunk';
import {Provider} from 'react-redux';
import './styles/global.module.scss';
import {LanguageStore} from "./contexts/LanguageContext";

const composeEnhancers = compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(reduxThunk)));

ReactDOM.render(
    <LanguageStore>
        <Provider store={store}>
            <App/>
        </Provider>
    </LanguageStore>,


    document.querySelector('#root')
);

