import React from 'react';
import './StyledButton.scss';
import {FormattedMessage} from "react-intl";

const StyledButton = ({type, onClick, text, disabled}) => {
    return (
        <button className={`tbl-button tbl-styled-btn ${type}`} onClick={onClick} disabled={disabled}>
            <FormattedMessage id={text} defaultMessage={text}/>
        </button>
    );
}

StyledButton.defaultProps = {
    disabled: false
};

export default StyledButton;