import React from "react";
import {optIn} from '../actions';
import {connect} from "react-redux";
import ToggleSwitchWithLabel from "./ToggleSwitchWithLabel/ToggleSwitchWithLabel";

class OptIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            checked: true
        };
    }

    onOptIn = () => {
        this.props.optIn();
        this.setState({checked: true});
    }

    render() {
        return (
            <React.Fragment>
                <ToggleSwitchWithLabel
                    text='sar.optout.button'
                    checked={this.state.checked}
                    label='sar.optout.label'
                    onChange={this.onOptIn}/>
            </React.Fragment>
        );
    }
};

export default connect(null, {optIn})(OptIn);

